import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/cluster';

export const getClusterList = async (token) => {
  let list = null;
  try {
    list = await axios.get(API_BASE_URL + "?status=All", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return list.data;
  } catch (error) {
    console.log("ClusterService -> getClusterList: " + error);
  }
};

export const getActiveClusterListByPlanId = async (token,planId) => {
  let list = null;
  try {
    list = await axios.get(API_BASE_URL + "?planId="+planId+"&status=Active", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return list.data;
  } catch (error) {
    console.log("ClusterService -> getClusterList: " + error);
  }
};

export const getClusterListByPlanId = async (token,planId) => {
  let list = null;
  try {
    list = await axios.get(API_BASE_URL + "?planId="+planId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return list.data;
  } catch (error) {
    console.log("ClusterService -> getClusterListByPlanId: " + error);
  }
};

export const saveCluster = async (token, cluster) => {
  let response = null;
  try {
    response = await axios.post(API_BASE_URL, cluster, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("ClusterService -> saveCluster: " + error);
  } finally {
    return response.data;
  }
};

export const updateCluster = async (token, cluster) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL + "/" + cluster.id, cluster, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("ClusterService -> updateCluster: " + error);
  } finally {
    return response.data;
  }
};

export const deleteCluster = async (token, clusterId) => {
  let response = null;
  try {
    response = await axios.delete(API_BASE_URL + "/" + clusterId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("ClusterService -> deleteCluster: " + error);
  } finally {
    return response.data;
  }
};

export const deActivateCluster = async (token, clusterId) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL +"/"+ clusterId+"/deactivate" , "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("ClusterService -> deActivateCluster: " + error);
  } finally {
    return response.data;
  }
};

export const activateCluster = async (token, clusterId) => {
  let response = null;
  try {
    response = await axios.put(API_BASE_URL + "/"+ clusterId+"/activate", "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("ClusterService -> activateCluster: " + error);
  } finally {
    return response.data;
  }
};