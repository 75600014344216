import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/assortment';

export const getAssortmentList = async(token) => {
  let list = null
  try{
    list = await axios.get(API_BASE_URL+'?status=All',{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return list.data;
  }catch(error){             
      console.log('AssortmentService -> getAssortmentList: '+error);
  }
}

export const saveAssortment = async(token,assortment) => {
  let response = null   
  try{
      response = await axios.post(API_BASE_URL,assortment,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('AssortmentService -> saveAssortment: '+error);
  }finally {
      return response.data;
  }
}

export const getAssortmentById = async(token,assortmentId) => {
  let assortment = null
  try{
    assortment = await axios.get(API_BASE_URL+'/'+assortmentId,{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return assortment.data;
  }catch(error){             
      console.log('AssortmentService -> getAssortmentById: '+error);
  }
}

export const updateAssortment = async(token,assortment) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/'+assortment.id,assortment,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('AssortmentService -> updateAssortment: '+error);
  }finally {
      return response.data;
  }
}

export const deleteAssortment = async (token, assortmentId) => {
  let response = null;
  try {
    response = await axios.delete(API_BASE_URL + "/" + assortmentId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    response = error.response;
    console.log("AssortmentService -> deleteAssortment: " + error);
  } finally {
    return response.data;
  }
};


export const deActivateAssortment = async(token,assortmentId) => {
  let response = null   
  try{        
      response = await axios.put(API_BASE_URL+'/'+assortmentId+'/deactivate','',{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                
      console.log('AssortmentService -> deActivateAssortment: '+error);
  }finally {
      return response.data;
  }
}

export const activateAssortment = async(token,assortmentId) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/'+assortmentId+'/activate','',{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                
      console.log('AssortmentService -> activateAssortment: '+error);
  }finally {
      return response.data;
  }
}
