import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL+'/assortmentItem';

export const saveAssortmentItemList = async(token,assortmentItemList) => {
  let response = null   
  try{
      response = await axios.post(API_BASE_URL,assortmentItemList,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('AssortmentItemService -> saveAssortmentItemList: '+error);
  }finally {
      return response.data;
  }
}

export const updateAssortmentItemList = async(token,assortmentId,assortmentItemList) => {
  let response = null   
  try{
      response = await axios.put(API_BASE_URL+'/'+assortmentId,assortmentItemList,{ 
          headers: {                                                                
                  'Authorization': `Bearer ${token}`                                  
                  }
          });   
          
  }catch(error){  
      response = error.response;                  
      console.log('AssortmentItemService -> saveAssortmentItemList: '+error);
  }finally {
      return response.data;
  }
}

export const getAssortmentItemListByAssortmentId = async(token,assortmentId) => {
  let list = null
  try{
    list = await axios.get(API_BASE_URL+'?assortmentId='+assortmentId,{ 
          headers: {                                                                                                   
                  'Authorization': `Bearer ${token}`                                                   
                  }            
          });            
      return list.data;
  }catch(error){             
      console.log('AssortmentItemService -> getAssortmentItemListByAssortmentId: '+error);
  }
}

export const deleteAssortmentItemsByAssortmentId = async (token, assortmentId) => {
    let response = null;
    try {
      response = await axios.delete(API_BASE_URL + "/" + assortmentId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      response = error.response;
      console.log("AssortmentItemService -> deleteAssortmentItemsByAssortmentId: " + error);
    } finally {
      return response.data;
    }
};
