import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/azure/blob";

export const uploadFile = async (token, file, folder) => {
  let res = null;
  const formData = new FormData();
  formData.append('file',file)
  try {
      res = await axios.post(API_BASE_URL+"?folder="+folder, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "Cache-Control": "no-cache",
      },
    });
  } catch (error) {
    res = error.res;
    console.log("AzureBlobService -> uploadFile: " + error);
  } finally {
    return res.data;
  }
};