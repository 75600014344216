import { useRef, useState, useEffect, useCallback, useMemo } from "react";

import {
  FaCaretLeft,
  FaCaretRight,
  FaPlus,
  FaCircleXmark,
  FaSquare,
  FaCircle,
} from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import 'ag-grid-community/styles/ag-theme-quartz.css'; 
import Notification from "../component/utility/Notification";
import { activateItem, deActivateItem, deleteItem, generateItemCode, getItemList, saveItem, updateItem } from "../service/ItemService";
import { getActiveCategoryList, getActiveCategoryListOrderByAsc } from "../service/CategoryService";
import { uploadFile } from "../service/AzureBlobService";


export default function Item() {

  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  /****** Left Pane - start *****************************************************************************************************************/
  const [leftPaneStyle, setLeftPaneStyle] = useState("w-1/5");
  const [isLeftPaneExpanded, setIsLeftPaneExpanded] = useState(false);
  const [leftPaneExpandedButtonIcon, setLeftPaneExpandedButtonIcon] = useState(
    <FaCaretRight size={36} />
  );

  //++++  Item List DataTable  ++++++++++++++++++++++++++++++++
  const itemTableRef = useRef();
  const [paginationlHide, setPaginationlHide] = useState(true);
  const [itemTableRowData, setItemTableRowData] = useState([]);

  const [itemTableColumnDefs, setItemTableColumnDefs] = useState([
    {
      colId: "id",
      valueGetter: (r) => r.data.id,
      flex: 1,
      hide: true,
    },    
    {
      colId: "code",
      headerName: "Item Code",
      valueGetter: (r) => r.data.itemCode,
      filter: true,
      flex: 1,
    },
    {
      colId: "name",
      headerName: "Name",
      valueGetter: (r) => r.data.name,
      filter: true,
      flex: 1,
      hide: true,
    },
    {
      colId: "category",
      headerName: "Category",
      valueGetter: (r) => r.data.category.name,
      filter: true,
      flex: 1,
      hide: true,
    },
    {
      colId: "manufacturer",
      headerName: "Manufacturer",
      valueGetter: (r) => r.data.manufacturer,
      filter: true,
      flex: 1,
      hide: true,
    },    
    {
      colId: "createdAt",
      headerName: "Registered Date",
      valueGetter: (r) => r.data.createdAt.replace("T", " "),
      flex: 1,
      hide: true,
    },
    {
      colId: "active",
      headerName: "Status",
      valueGetter: (r) => r.data.active,
      filter: true,
      flex: 1,
      hide: true,
    },
  ]);

  // functions
  const handleExpandLeftPane = () => {
    if (!isLeftPaneExpanded) setIsLeftPaneExpanded(true);
    else setIsLeftPaneExpanded(false);

    if (isLeftPaneExpanded) {
      setLeftPaneStyle("w-1/5");
      setLeftPaneExpandedButtonIcon(<FaCaretRight size={36} />);

      itemTableRef.current.api.setColumnsVisible(
        ["name", "category", "manufacturer","createdAt", "active"],
        false
      );
      setPaginationlHide(true);
    } else {
      setLeftPaneStyle("w-screen");
      setLeftPaneExpandedButtonIcon(<FaCaretLeft size={36} />);

      itemTableRef.current.api.setColumnsVisible(
        ["name", "category", "manufacturer","createdAt", "active"],
        true
      );
      setPaginationlHide(false);
    }
  };

  const fetchItemTableData = async () => {
    try {
      const res = await getItemList(loggedUser.token);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
        }
        setItemTableRowData(res.data);
      } else {
        setItemTableRowData([]);
      }
    } catch (error) {
      console.error("Item -> fetchItemTableData: ", error);
    }
  };


  useEffect(() => {
    fetchItemTableData();
  }, []);



  const handledOnRowClick = useCallback( async() => {
    const selectedRows = itemTableRef.current.api.getSelectedRows();
    
    if (selectedRows.length === 1) {
      setItemFormData((prevState) => ({
        ...prevState,
        id: selectedRows[0].id,
        name: selectedRows[0].name,
        refName: selectedRows[0].refName,
        itemCode: selectedRows[0].itemCode,
        imageUrl: selectedRows[0].imageUrl,
        hasWarranty: selectedRows[0].hasWarranty,
        manufacturer: selectedRows[0].manufacturer,
        manufacturerItemCode: selectedRows[0].manufacturerItemCode,
        manufacturerItemName: selectedRows[0].manufacturerItemName,
        active: selectedRows[0].active,       
        categoryId: selectedRows[0].category.id,
      }));
      
      setOperationMode("update");
      setApiResponse({
        status: "",
        message: "Finished loading : " + selectedRows[0].name,
      });
      setItemStatus(selectedRows[0].active);
      setIsValidInputs(true);
      setExistItemCode(selectedRows[0].itemCode)     

    }
  }, []);

  /****** Left Pane - end *******************************************************************************************************************/

  /****** Right Pane - start ****************************************************************************************************************/

  //++++ Button Container  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [operationMode, setOperationMode] = useState("new"); // "new", "save", or "update"
  const [apiResponse, setApiResponse] = useState({
    //status: true, data: null, message: 'Already added : Fabric'
    status: "",
    message: "",
  });

  const [itemStatus, setItemStatus] = useState("Active");

  const handleNewButton = async () => {
    initialize();    
    setApiResponse({ status: "", message: "Add New Item" });    
  };

  const handleSaveButton = async(e) => {
    e.preventDefault();
    setIsValidInputs(true);    
    
    let resItem = null;
    let resImageUrl = null;

    if (isValidInputs()) {
      // image upload and get imageUrl
      if(image){
        const newFileName = itemFormData.itemCode+'.'+image.name.split('.').pop();
        const renamedImage = new File([image],newFileName,{
          type: image.type,
        })
       
        let res= await uploadFile(loggedUser.token, renamedImage,'items');         
        resImageUrl = res.data
      }else{        
        if(itemFormData.imageUrl){
          resImageUrl = itemFormData.imageUrl          
        }else{
          resImageUrl = ''
        }          
      }     
      
      let item = {      
        id: itemFormData.id,
        name: itemFormData.name.toUpperCase(),
        refName: itemFormData.refName,
        itemCode: itemFormData.itemCode,
        imageUrl: resImageUrl,
        hasWarranty: itemFormData.hasWarranty,
        manufacturer:itemFormData.manufacturer,
        manufacturerItemCode: itemFormData.manufacturerItemCode,
        manufacturerItemName: itemFormData.manufacturerItemName,
        active: itemFormData.active,       
        categoryId: itemFormData.categoryId,
      };  

      

      if (operationMode === "new") {        
        resItem = await saveItem(loggedUser.token, item);
        setApiResponse({ status: resItem.status, message: resItem.message });

        if (resItem != null) {
          if (resItem.status) {
            initialize();
          }
        }

      } else if (operationMode === "update") {        
        resItem = await updateItem(loggedUser.token, item);
        setApiResponse({ status: resItem.status, message: resItem.message });

        if (resItem != null) {
          if (resItem.status) {
            initialize();
          }
        }
      } else {
        setApiResponse({ status: false, message: "Error" });
      }
    } else {
      setApiResponse({
        status: false,
        message: "Error saving : Invalid inputs",
      });
    }      

    fetchItemTableData();
  };
    

  const handleDeleteButton = async (e) => {
    e.preventDefault();
    let res = null;

    if (itemFormData.name) {      
      res = await deleteItem(loggedUser.token, itemFormData.id);
      setApiResponse({ status: res.status, message: res.message });

      if (res.status) {
        initialize();
      } else {
        setApiResponse({ status: false, message: "Error deleting..." });
      }        
    }
      
    fetchItemTableData();
    
  };

  const handleActiveButton = async (e) => {
    e.preventDefault();
    let res = null;

    if (itemStatus === "Active") {
      res = await deActivateItem(loggedUser.token, itemFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setItemStatus("Inactive");
    }

    if (itemStatus === "Inactive") {
      res = await activateItem(loggedUser.token, itemFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setItemStatus("Active");
    }

    fetchItemTableData();
  };

  //++++ Item info Tab Pane  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Basic Inputs
  const [itemFormData, setItemFormData] = useState({
    id: "",
    name: "",
    refName: "",
    itemCode: "",
    imageUrl: "",
    hasWarranty: "No",
    manufacturer:"",
    manufacturerItemCode: "",
    manufacturerItemName: "",
    active: "Active",       
    categoryId: 0,
    errors: {},
  });

  // use for update item => stop duplicate same item
  const [existItemCode, setExistItemCode] = useState('');

  // item image
  const[image , setImage] = useState('');


  const handleBasicInputChange = (e) => {
    const { name, value } = e.target;
    setItemFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // category drop down box  
  const [activeCategoryList, setActiveCategoryList] = useState([]);

  const fetchActiveCategoryList = async () => {
    try {
      const req = await getActiveCategoryListOrderByAsc(loggedUser.token);
      setActiveCategoryList(req.data);
    } catch (error) {
      console.log(error);
    }
  };

  // fetch category list to dropdown
  useEffect(() => {
    fetchActiveCategoryList();
  }, []);
  

  // category dropdown select change
  const handleCategorySelectChange = async (e) => {  
    handleBasicInputChange(e);
    try {
      // generate item code
      let res = await generateItemCode(loggedUser.token,e.target.value);      
      if(res.status){
        let newItemCode = res.data;
                
        if(newItemCode.split('-')[0] != existItemCode.split('-')[0]){
          setItemFormData((prevState) => ({ ...prevState, itemCode: newItemCode })); 
        }else{
          setItemFormData((prevState) => ({ ...prevState, itemCode: existItemCode })); 
        }
              
      }else{
        setItemFormData((prevState) => ({ ...prevState, itemCode: ''})); 
        setApiResponse({ status: false, message: "Server Error" });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // image browser handler   
  const handleImageChange = (e) =>{    
    const file = e.target.files[0];  // Get the selected file
    if (file) {
      setImage(file);  // Set the file to state  
      setItemFormData((prevState) => ({ ...prevState, imageUrl: URL.createObjectURL(file) }));    
      
    }
  }

  // image clear handler
  const handleClearImage = (e) =>{    
    setImage('')   
    setItemFormData((prevState) => ({ ...prevState, imageUrl: ''}));
  }

  // Validation
  const [isValidItemCode, setIsValidItemCode] = useState(true);
  const [isValidName, setIsValidName] = useState(true);

  const isValidInputs = () => {
    const errors = {};

    if (itemFormData.itemCode === "") {
      setIsValidItemCode(false);
      errors.code = "Code is Required";
    }

    if (!itemFormData.name) {
      setIsValidName(false);
      errors.name = "Name is Required";
    }   

    setItemFormData((prevState) => ({ ...prevState, errors }));
    
    // return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // change input validation state
  const setIsValidInputs = (isValid) => {
    setIsValidItemCode(isValid);
    setIsValidName(isValid);    
  };

  // clear inputs
  const clearFormInputs = () => {
    setItemFormData({
      id: "",
      name: "",
      refName: "",
      itemCode: "",
      imageUrl: "",
      hasWarranty: "No",
      manufacturer:"",
      manufacturerItemCode: "",
      manufacturerItemName: "",
      active: "Active",       
      categoryId: 0,
      errors: {},
    });

    setImage('')
    setExistItemCode('')
  };

  // initialize inputs and state
  const initialize = () => {
    //basic
    clearFormInputs();
    setOperationMode("new");
    setIsValidInputs(true);
    setItemStatus("Active");
  };

  

  //++++ Tab Layout  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [tab_1_Hide, setTab_1_Hide] = useState("");
  const [tab_2_Hide, setTab_2_Hide] = useState("hidden");
  const [tab_3_Hide, setTab_3_Hide] = useState("hidden");

  const TAB_ACTIVE_CSS = "text-master_color-400 border-master_color-400";
  const [tab_1_active_css, setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
  const [tab_2_active_css, setTab_2_active_css] = useState("");
  const [tab_3_active_css, setTab_3_active_css] = useState("");

  const toggleTab = (id) => {
    if (id === 1) {
      setTab_1_Hide("");
      setTab_2_Hide("hidden");
      setTab_3_Hide("hidden");

      setTab_1_active_css(TAB_ACTIVE_CSS);
      setTab_2_active_css("");
      setTab_3_active_css("");
    }

    if (id === 2) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("");
      setTab_3_Hide("hidden");

      setTab_1_active_css("");
      setTab_2_active_css(TAB_ACTIVE_CSS);
      setTab_3_active_css("");
    }

    if (id === 3) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("hidden");
      setTab_3_Hide("");

      setTab_1_active_css("");
      setTab_2_active_css("");
      setTab_3_active_css(TAB_ACTIVE_CSS);
    }
  };

  /****** Right Pane - end ******************************************************************************************************************/

  return (
    <div className="flex h-full">
      <div id="left-pane" className="w-1/5 z-10">
        <div
          className={`bg-white absolute top-0 bottom-0 ${leftPaneStyle}  border-solid border-r border-slate-300`}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 mt-1.5 ml-2.5">
              <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow mb-1.5">
                Item List
              </p>
            </div>
            <div className="col-start-2 flex justify-end">
              <button
                onClick={handleExpandLeftPane}
                className="text-master_color-400 font-bold rounded mt-1 mr-1"
              >
                {leftPaneExpandedButtonIcon}
              </button>
            </div>
            <div className="col-span-2 p-0"></div>
          </div>
          <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

          <div className={`h-[calc(100%_-_2.5rem)] p-2`}>
            <div className="ag-theme-quartz h-full">
              <AgGridReact
                ref={itemTableRef}
                columnDefs={itemTableColumnDefs}
                rowData={itemTableRowData}
                pagination={!paginationlHide}
                paginationPageSize={10}
                paginationPageSizeSelector={[5, 10, 15]}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowSelection={"single"}
                onSelectionChanged={handledOnRowClick}
                //onRowDoubleClicked={handledOnRowDoubleClick}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="right-pane" className="w-4/5 z-0">
        <div id="button-container" className="flex gap-1.5 pl-2">
          <div className="mt-1">
            <button
              type="button"
              onClick={handleNewButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaPlus size={16} />
              &nbsp;New
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={itemStatus === "Active" ? false : true}
              onClick={handleSaveButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaSave size={16} />
              &nbsp;Save
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleDeleteButton}
              className="px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaCircleXmark size={16} />
              &nbsp;Delete
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleActiveButton}
              className="w-28 px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              {itemStatus === "Active" ? (
                <FaSquare size={12} color="red" />
              ) : (
                <FaCircle size={12} color="green" />
              )}
              &nbsp;
              {itemStatus === "Active" ? "Deactivate" : "Reactivate"}
            </button>
          </div>
          <div className="mt-2.5 ml-6 flex w-auto">
            <Notification response={apiResponse} />
          </div>
        </div>

        <hr className="border-slate-300 border-0.5 mt-1"></hr>

        <div id="tab-container" className="h-[calc(100%_-_6.5rem)]">
          <div
            id="item-info-tab-pane"
            className={`p-6 pt-1 ${tab_1_Hide}`}
            role="tabpanel"
          >
            <div className="grid grid-cols-3 gap-12">
              <div id="row-1-col-1">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Basic
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Item Name
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="name"
                      value={itemFormData.name}
                      onChange={handleBasicInputChange}                      
                      placeholder="Required"
                      autocomplete="off" 
                      className={`w-full text-sm uppercase pl-2 py-1 outline-none bg-gray-100 border focus:border-master_color-200 rounded
                            ${
                              itemStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            }
                            ${
                              isValidName
                                ? "border-slate-400"
                                : "border-red-600"
                            }                             
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Category</div>
                  <div className="col-span-2">
                    <select
                      name="categoryId"
                      value={itemFormData.categoryId}
                      onChange={handleCategorySelectChange}
                      disabled={itemStatus === "Active" ? false : true}
                      className={`w-full pl-1 py-1 text-sm outline-none border focus:border-master_color-200 rounded                                    
                                    ${
                                      itemStatus === "Active"
                                        ? "bg-white"
                                        : "bg-gray-200"
                                    }
                                    `}
                    >
                      <option value="0" disabled>
                        -- select category --
                      </option>
                      {activeCategoryList.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Item Code
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="itemCode"
                      value={itemFormData.itemCode}
                      onChange={handleBasicInputChange}
                      readOnly
                      placeholder="Required"
                      className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border focus:border-master_color-200 rounded
                            ${
                              itemStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            }
                            ${
                              isValidItemCode
                                ? "border-slate-400"
                                : "border-red-600"
                            }                             
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Reference Name
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="refName"
                      value={itemFormData.refName}
                      onChange={handleBasicInputChange}              
                      autocomplete="off" 
                      className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border border-slate-400 focus:border-master_color-200 rounded
                            ${
                              itemStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            }                                                         
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Warranty</div>
                  <div className="col-span-2">
                    <select
                      name="hasWarranty"
                      value={itemFormData.hasWarranty}
                      onChange={handleBasicInputChange}
                      disabled={itemStatus === "Active" ? false : true}
                      className={`w-full pl-1 py-1 text-sm outline-none border border-slate-400 focus:border-master_color-200 rounded                                     
                                ${
                                  itemStatus === "Active"
                                    ? "bg-white"
                                    : "bg-gray-200"
                                }
                                `}
                    >
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>                
              </div>
              <div id="row-1-col-2">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Image
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="col-span-3 w-full h-44 border-2 rounded-md bg-slate-100 overflow-auto items-center justify-center flex flex-col">
                    <img                      
                      src={itemFormData.imageUrl}                                          
                    />
                  </div>                 
                </div>
                
                <button className="border rounded inline-block cursor-pointer mt-1 px-5 py-1 text-xs font-medium text-center text-white  bg-master_color-400 hover:bg-master_color-200 float-right" onClick={handleClearImage}>Clear</button> 
                <label for="file-upload" className="border rounded inline-block cursor-pointer mt-1 px-4 py-1 text-xs font-medium text-center text-white bg-master_color-400 hover:bg-master_color-200 float-right">
                    Browse
                </label>
                <input id="file-upload" type="file" className="hidden" onChange={handleImageChange}/>                 
              </div>
              <div id="row-1-col-3"></div>

              <div id="row-2-col-1">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Manufacturer Info
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Name
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="manufacturer"
                      value={itemFormData.manufacturer}
                      onChange={handleBasicInputChange}              
                      autocomplete="off" 
                      className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border border-slate-400 focus:border-master_color-200 rounded
                            ${
                              itemStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            }                                                       
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Item Code
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="manufacturerItemCode"
                      value={itemFormData.manufacturerItemCode}
                      onChange={handleBasicInputChange}              
                      autocomplete="off" 
                      className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border border-slate-400 focus:border-master_color-200 rounded
                            ${
                              itemStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            }                                                       
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Item Name
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="manufacturerItemName"
                      value={itemFormData.manufacturerItemName}
                      onChange={handleBasicInputChange}              
                      autocomplete="off" 
                      className={`w-full text-sm pl-2 py-1 outline-none bg-gray-100 border border-slate-400 focus:border-master_color-200 rounded
                            ${
                              itemStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            }                                                       
                            `}
                    />
                  </div>
                </div>
              </div>
              <div id="row-2-col-2"></div>
              <div id="row-2-col-3"></div>
            </div>
          </div>

          <div
            id="tab-pane"
            className={`p-4 pt-1 ${tab_2_Hide} h-full`}
            role="tabpanel"
          >
            Order History Tab
          </div>

          <div
            id="tab-pane"
            className={`p-4 pt-1 ${tab_3_Hide} h-full`}
            role="tabpanel"
          >
            Movement History Tab
          </div>
        </div>

        <div
          id="tab-button-container"
          className="mb-4 border-b border-gray-200"
        >
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-styled-tab"
            data-tabs-toggle="#default-styled-tab-content"
            data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 "
            data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`}
                id="profile-styled-tab"
                data-tabs-target="#styled-profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => toggleTab(1)}
              >
                Item Info
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4  border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(0)}
              >
                Order History
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_3_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(0)}
              >
                Movement History
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
