import { useRef, useState } from "react";

import {
  FaCaretLeft,
  FaCaretRight,
  FaPlus,
  FaCircleXmark,
  FaSquare,
  FaCircle,
} from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import {
  deleteCategory,
  getCategoryList,
  saveCategory,
  updateCategory,
  deActivateCategory,
  activateCategory,
} from "../service/CategoryService";
import { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import 'ag-grid-community/styles/ag-theme-quartz.css'; 
import { useMemo } from "react";
import Notification from "../component/utility/Notification";
import { useCallback } from "react";
import { getItemListByCategoryId } from "../service/ItemService";

export default function Category() {
  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  /****** Left Pane - start ***************************************************************/

  const [leftPaneStyle, setLeftPaneStyle] = useState("w-1/5");
  const [isLeftPaneExpanded, setIsLeftPaneExpanded] = useState(false);
  const [leftPaneExpandedButtonIcon, setLeftPaneExpandedButtonIcon] = useState(
    <FaCaretRight size={36} />
  );

  //++++ Category List DataTable  ++++++++++++++++++++++++++++++++
  const categoryTableRef = useRef();
  const [categoryTableRowData, setCategoryTableRowData] = useState([]);
  const [paginationlHide, setPaginationlHide] = useState(true);

  const categoryNameCellComponent = (row) => {
    return (
      <div className="flex items-center">
        <div
          className="w-7 h-7 border rounded-full mr-2"
          style={{ backgroundColor: row.data.colorCode }}
        ></div>
        <span>{row.data.name}</span>
      </div>
    );
  };

  const [categoryTableColumnDefs, setCategoryTableColumnDefs] = useState([
    { 
      colId: "id", 
      valueGetter: (r) => r.data.id, 
      flex: 1,
      hide: true 
    },
    {
      colId: "index",
      headerName: "#",
      valueGetter: (r) => r.data.index,
      flex: 0.2,
      hide: true,
    },
    {
      colId: "name",
      headerName: "Name",
      cellRenderer: categoryNameCellComponent,
      flex: 1,
      
    },
    {
      colId: "code",
      headerName: "Code",
      valueGetter: (r) => r.data.code,
      filter:true,
      flex: 1,
      hide: true,
    },
    {
      colId: "active",
      headerName: "Status",
      valueGetter: (r) => r.data.active,
      flex: 1,
      hide: true,
      filter: true
    },
  ]);

  // functions
  const handleExpandLeftPane = () => {
    if (!isLeftPaneExpanded) setIsLeftPaneExpanded(true);
    else setIsLeftPaneExpanded(false);

    if (isLeftPaneExpanded) {
      setLeftPaneStyle("w-1/5");
      setLeftPaneExpandedButtonIcon(<FaCaretRight size={36} />);
      categoryTableRef.current.api.setColumnsVisible(
        ["code","active"],
        false
      );
      setPaginationlHide(true);
    } else {
      setLeftPaneStyle("w-screen");
      setLeftPaneExpandedButtonIcon(<FaCaretLeft size={36} />);
      categoryTableRef.current.api.setColumnsVisible(
        ["code", "active"],
        true
      );
      setPaginationlHide(false);
    }
  };

  const fetchCategoryTableData = async () => {
    try {
      const res = await getCategoryList(loggedUser.token);

      for (let i = 0; i < res.data.length; i++) {
        res.data[i].index = i + 1;
      }

      setCategoryTableRowData(res.data);
    } catch (error) {
      console.error("Category -> fetchCategoryTableData: ", error);
    }
  };

  useEffect(() => {
    fetchCategoryTableData();
  }, []);

  const handledOnRowClick = useCallback(() => {
    const selectedRows = categoryTableRef.current.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setCategoryFormData((prevState) => ({
        ...prevState,
        id: selectedRows[0].id,
        name: selectedRows[0].name,
        code: selectedRows[0].code,
        colorCode: selectedRows[0].colorCode,
        active: selectedRows[0].active,
      }));

      setOperationMode("update");
      setApiResponse({
        status: "",
        message: "Finished loading : " + selectedRows[0].name,
      });
      setCategoryStatus(selectedRows[0].active);
      setIsValidInputs(true);

      fetchItemListTableData(selectedRows[0].id);
    }
  }, []);

  /****** Left Pane - end ***************************************************************/

  /****** Right Pane - start ***************************************************************/
  //++++ Button Container  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [operationMode, setOperationMode] = useState("new"); // "new", "save", or "update"
  const [apiResponse, setApiResponse] = useState({
    //status: true, data: null, message: 'Already added : Fabric'
    status: "",
    message: "",
  });
  const [categoryStatus, setCategoryStatus] = useState("Active");

  const handleNewButton = () => {
    initialize();
    setApiResponse({ status: "", message: "Add new Category" });
  };

  const handleSaveButton = async (e) => {
    e.preventDefault();
    setIsValidInputs(true);

    let category = {
      id: categoryFormData.id,
      name: categoryFormData.name,
      code: categoryFormData.code,
      colorCode: categoryFormData.colorCode,
      active: categoryFormData.active,
    };

    let res = null;

    if (isValidInputs()) {
      if (operationMode === "new") {
        res = await saveCategory(loggedUser.token, category);
        setApiResponse({ status: res.status, message: res.message });
        if (res.status) {
          initialize();
        }
      } else if (operationMode === "update") {
        res = await updateCategory(loggedUser.token, category);
        setApiResponse({ status: res.status, message: res.message });
        if (res.status) {
          initialize();
        }
      } else {
        setApiResponse({ status: false, message: "Error" });
      }
    } else {
      setApiResponse({
        status: false,
        message: "Error saving : Invalid inputs",
      });
    }

    fetchCategoryTableData();
  };

  const handleDeleteButton = async (e) => {
    e.preventDefault();
    let res = null;
    if (categoryFormData.name !== "" || categoryFormData.name.length !== 0) {
      res = await deleteCategory(loggedUser.token, categoryFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      if (res.status) {
        clearFormInputs();
      } else {
        setApiResponse({ status: false, message: "Error deleting..." });
      }
    }

    fetchCategoryTableData();
  };

  const handleActiveButton = async (e) => {
    e.preventDefault();
    let res = null;

    if (categoryStatus === "Active") {
      res = await deActivateCategory(loggedUser.token, categoryFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setCategoryStatus("Inactive");
    }

    if (categoryStatus === "Inactive") {
      res = await activateCategory(loggedUser.token, categoryFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setCategoryStatus("Active");
    }

    fetchCategoryTableData();
  };

  //++++ Item info Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++
  // input values
  const [categoryFormData, setCategoryFormData] = useState({
    id: "",
    name: "",
    code: "",
    colorCode: "#ffffff",
    active: "Active",
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCategoryFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // validation
  const [isValidName, setIsValidName] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);
  const [isValidColorCode, setIsValidColorCode] = useState(true);

  const isValidInputs = () => {
    const errors = {};

    if (categoryFormData.name === "" || categoryFormData.name.length === 0) {
      setIsValidName(false);
      errors.name = "Name is Required";
    }

    if (categoryFormData.code === "" || categoryFormData.code.length === 0) {
      setIsValidCode(false);
      errors.name = "Code is Required";
    }

    if (categoryFormData.colorCode === "#ffffff" || categoryFormData.colorCode.length === 0) {
      setIsValidColorCode(false);
      errors.name = "Color is Required";
    }

    setCategoryFormData((prevState) => ({ ...prevState, errors }));
    // return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // change input validation state
  const setIsValidInputs = (isValid) => {
    setIsValidName(isValid);
    setIsValidCode(isValid);
    setIsValidColorCode(isValid);
  };

  // clear inputs
  const clearFormInputs = () => {
    setCategoryFormData({
      id: "",
      name: "",
      code: "",
      colorCode: "#ffffff",
      active: "Active",
      errors: {},
    });
  };

  // initialize inputs and state
  const initialize = () => {
    clearFormInputs();
    setOperationMode("new");
    setIsValidInputs(true);
    setCategoryStatus("Active");
  };

  //++++ Item List Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++ Item List DataTable  ++++++++++++++++++++++++++++++++
  const itemListTableRef = useRef();
  const [itemListTableRowData, setItemListTableRowData] = useState([]);

  const [itemListTableColumnDefs, setItemListTableColumnDefs] = useState([
    { colId: "id", valueGetter: (r) => r.data.id, flex: 1, hide: true },
    {
      colId: "index",
      headerName: "#",
      //valueGetter: (r) => r.data.index,
      flex: 0.12,
    },
    {
      colId: "name",
      headerName: "Name",
      valueGetter: (r) => r.data.name,
      filter: true,
      flex: 1,
    },
    {
      colId: "price",
      headerName: "Last Purchasing Price",
      valueGetter: "0.00",
      flex: 1,
    },
    {
      colId: "status",
      headerName: "Status",
      valueGetter: (r) => r.data.active,
      filter: true,
      flex: 1,
    },
  ]);

  // TODO: Update after finished item.jsx
  const fetchItemListTableData = async (categoryId) => {
    try {
      const res = await getItemListByCategoryId(loggedUser.token,categoryId);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
        }

        setItemListTableRowData(res.data);
      } else {
        setItemListTableRowData([]);
      }
    } catch (error) {
      console.error("Category -> fetchItemListTableData: ", error);
    }
  };

  //++++ Tab Layout  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [tab_1_Hide, setTab_1_Hide] = useState("");
  const [tab_2_Hide, setTab_2_Hide] = useState("hidden");

  const TAB_ACTIVE_CSS = "text-master_color-400 border-master_color-400";
  const [tab_1_active_css, setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
  const [tab_2_active_css, setTab_2_active_css] = useState("");

  const toggleTab = (id) => {
    if (id === 1) {
      setTab_1_Hide("");
      setTab_2_Hide("hidden");

      setTab_1_active_css(TAB_ACTIVE_CSS);
      setTab_2_active_css("");
    }

    if (id === 2) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("");

      setTab_1_active_css("");
      setTab_2_active_css(TAB_ACTIVE_CSS);
    }
  };
  /****** Right Pane - end *****************************************************************/

  return (
    <div className="flex h-full">
      <div id="left-pane" className="w-1/5 z-10">
        <div
          className={`bg-white absolute top-0 bottom-0 ${leftPaneStyle} border-solid border-r border-slate-300`}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 mt-1.5 ml-2.5">
              <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow mb-1.5">
                Category List
              </p>
            </div>
            <div className="col-start-2 flex justify-end">
              <button
                onClick={handleExpandLeftPane}
                className="text-master_color-400 font-bold rounded mt-1 mr-1"
              >
                {leftPaneExpandedButtonIcon}
              </button>
            </div>
            <div className="col-span-2 p-0"></div>
          </div>
          <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

          <div className={`h-[calc(100%_-_2.5rem)] p-2`}>
            <div className="ag-theme-quartz h-full">
              <AgGridReact
                ref={categoryTableRef}
                columnDefs={categoryTableColumnDefs}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowData={categoryTableRowData}
                rowSelection={"single"}
                onSelectionChanged={handledOnRowClick}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="right-pane" className="w-4/5 z-0">
        <div id="button-container" className="flex gap-1.5 pl-2">
          <div className="mt-1">
            <button
              type="button"
              onClick={handleNewButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaPlus size={16} />
              &nbsp;New
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={categoryStatus === "Active" ? false : true}
              onClick={handleSaveButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaSave size={16} />
              &nbsp;Save
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleDeleteButton}
              className="px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaCircleXmark size={16} />
              &nbsp;Delete
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleActiveButton}
              className="w-28 px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              {categoryStatus === "Active" ? (
                <FaSquare size={12} color="red" />
              ) : (
                <FaCircle size={12} color="green" />
              )}
              &nbsp;
              {categoryStatus === "Active" ? "Deactivate" : "Reactivate"}
            </button>
          </div>
          <div className="mt-2.5 ml-6 flex w-auto">
            <Notification response={apiResponse} />
          </div>
        </div>

        <hr className="border-slate-300 border-0.5 mt-1"></hr>

        <div id="tab-container" className="h-[calc(100%_-_6.5rem)]">
          <div
            id="category-info-tab-pane"
            className={`p-6 pt-1 ${tab_1_Hide}`}
            role="tabpanel"
          >
            <div className="grid grid-cols-3 gap-12">
              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Basic
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Category Name
                  </div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="name"
                      value={categoryFormData.name}
                      onChange={handleInputChange}
                      disabled={categoryStatus === "Active" ? false : true}
                      placeholder="Required"
                      className={`w-full text-sm pl-2 py-1 outline-none border  focus:border-master_color-200 rounded
                            ${
                              isValidName
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              categoryStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Code</div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="code"
                      value={categoryFormData.code}
                      onChange={handleInputChange}
                      disabled={categoryStatus === "Active" ? false : true}
                      className={`w-full text-sm pl-2 py-1 outline-none border  focus:border-master_color-200 rounded
                        ${
                          isValidCode
                            ? "border-slate-400"
                            : "border-red-600"
                        }
                        ${
                          categoryStatus === "Active"
                            ? "bg-white"
                            : "bg-gray-200"
                        } 
                        `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Color Code</div>
                  <div className="col-span-2">
                    <input
                      type="color"
                      name="colorCode"
                      value={categoryFormData.colorCode}
                      onChange={handleInputChange}
                      disabled={categoryStatus === "Active" ? false : true}
                      placeholder="Required"
                      className={`w-full h-9 pl-0 py-0 px-0 border-0 rounded 
                            ${
                              isValidColorCode
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              categoryStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`px-4 py-1 ${tab_2_Hide} h-full`}
            id="styled-dashboard"
            role="tabpanel"
          >
            <div className="ag-theme-quartz h-full pt-1">
              <AgGridReact
                ref={itemListTableRef}
                columnDefs={itemListTableColumnDefs}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowData={itemListTableRowData}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[5, 10, 15]}
              />
            </div>
          </div>
        </div>

        <div className="mb-4 border-b border-gray-200">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-styled-tab"
            data-tabs-toggle="#default-styled-tab-content"
            data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 "
            data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`}
                id="profile-styled-tab"
                data-tabs-target="#styled-profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => toggleTab(1)}
              >
                Category Info
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(2)}
              >
                Item List
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
