import { useRef, useState, useCallback } from "react";

import {
  FaCaretLeft,
  FaCaretRight,
  FaPlus,
  FaCircleXmark,
  FaSquare,
  FaCircle,
} from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import {
  deletePlan,
  getPlanList,
  savePlan,
  updatePlan,
  deActivatePlan,
  activatePlan,
} from "../service/PlanService";
import { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { useMemo } from "react";
import Notification from "../component/utility/Notification";
import { getPlanValueListByPlanId, savePlanValue } from "../service/PlanValueService";
import { getClusterListByPlanId } from "../service/ClusterService";

export default function Plan() {
  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  /****** Left Pane - start ***************************************************************/

  const [leftPaneStyle, setLeftPaneStyle] = useState("w-1/5");
  const [isLeftPaneExpanded, setIsLeftPaneExpanded] = useState(false);
  const [leftPaneExpandedButtonIcon, setLeftPaneExpandedButtonIcon] = useState(
    <FaCaretRight size={36} />
  );

  //++++ Plan DataTable  ++++++++++++++++++++++++++++++++
  const planTableRef = useRef();
  const [planTableRowData, setPlanTableRowData] = useState([]);

  const [planTableColumnDefs, setPlanTableColumnDefs] = useState([
    { colId: "id", valueGetter: (r) => r.data.id, flex: 1, hide: true },
    {
      colId: "index",
      headerName: "#",
      valueGetter: (r) => r.data.index,
      flex: 0.3,
    },
    {
      colId: "code",
      headerName: "Plan Code",
      valueGetter: (r) => r.data.code,
      filter: true,
      flex: 1,
    },
    {
      colId: "createdAt",
      headerName: "Created Date",
      valueGetter: (r) => r.data.createdAt.replace("T", " "),
      flex: 1,
      hide: true,
    },
    {
      colId: "active",
      headerName: "Status",
      valueGetter: (r) => r.data.active,
      flex: 1,
      hide: true,
    },
  ]);

  // functions
  const handleExpandLeftPane = () => {
    if (!isLeftPaneExpanded) setIsLeftPaneExpanded(true);
    else setIsLeftPaneExpanded(false);

    if (isLeftPaneExpanded) {
      setLeftPaneStyle("w-1/5");
      setLeftPaneExpandedButtonIcon(<FaCaretRight size={36} />);

      planTableRef.current.api.setColumnsVisible(
        ["createdAt", "active"],
        false
      );
    } else {
      setLeftPaneStyle("w-screen");
      setLeftPaneExpandedButtonIcon(<FaCaretLeft size={36} />);

      planTableRef.current.api.setColumnsVisible(
        ["createdAt", "active"],
        true
      );
    }
  };

  const fetchPlanTableData = async () => {
    try {
      const res = await getPlanList(loggedUser.token);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
        }
        setPlanTableRowData(res.data);
      } else {
        setPlanTableRowData([]);
      }
    } catch (error) {
      console.error("plan -> fetchPlanTableData: ", error);
    }
  };

  useEffect(() => {
    fetchPlanTableData();
  }, []);

  const handledOnRowClick = useCallback(() => {
    const selectedRows = planTableRef.current.api.getSelectedRows();

    if (selectedRows.length === 1) {
      setPlanFormData((prevState) => ({
        ...prevState,
        id: selectedRows[0].id,
        code: selectedRows[0].code,
        active: selectedRows[0].active,
      }));

      setOperationMode("update");
      setApiResponse({
        status: "",
        message: "Finished loading : " + selectedRows[0].code,
      });
      setPlanStatus(selectedRows[0].active);
      setIsValidInputs(true);

      // fetch plan values
      fetchPlanValueTableData(selectedRows[0].id);

      // plan value inputs disabled
      setIsPlanValueInputDisabled(true);

      // fetch cluster list 
      fetchClusterListTableData(selectedRows[0].id)
    }
  }, []);

  /****** Left Pane - end ***************************************************************/

  /****** Right Pane - start ***************************************************************/
  //++++ Button Container  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [operationMode, setOperationMode] = useState("new"); // "new", "save", or "update"
  const [apiResponse, setApiResponse] = useState({
    //status: true, data: null, message: 'Already added : Fabric'
    status: "",
    message: "",
  });

  const [planStatus, setPlanStatus] = useState("Active");

  const handleNewButton = () => {
    initialize();
    setApiResponse({ status: "", message: "Add new plan" });
  };

  const handleSaveButton = async (e) => {
    e.preventDefault();
    setIsValidInputs(true);

    let plan = {
      id: planFormData.id,
      code: planFormData.code.toUpperCase(),
      active: planFormData.active,
    };
    

    let resPlan, resPlanValue = null;

    if (isValidInputs()) {
      if (operationMode === "new") {
        resPlan = await savePlan(loggedUser.token, plan);

        if (resPlan.status) {
          let planValue = {
            valueOfInstallment: planValueFormData.valueOfInstallment,
            noOfInstallment: planValueFormData.noOfInstallment,
            startDate: planValueFormData.startDate,
            endDate: "",
            planId: resPlan.data.id,
          };

          resPlanValue = await savePlanValue(
            loggedUser.token,            
            planValue
          );
        }

        setApiResponse({ status: resPlan.status, message: resPlan.message });

        if (resPlanValue != null) {
          if (resPlan.status & resPlanValue.status) {
            initialize();
          }
        }
      } else if (operationMode === "update") {
        resPlan = await updatePlan(loggedUser.token, plan);

        if (resPlan.status) {
          let planValue = {
            valueOfInstallment: planValueFormData.valueOfInstallment,
            noOfInstallment: planValueFormData.noOfInstallment,
            startDate: planValueFormData.startDate,
            endDate: "",
            planId: resPlan.data.id,
          };

          resPlanValue = await savePlanValue(
            loggedUser.token,           
            planValue
          );
        }

        setApiResponse({ status: resPlan.status, message: resPlan.message });

        if (resPlanValue != null) {
          initialize();
        }
      } else {
        setApiResponse({ status: false, message: "Error" });
      }
    } else {
      setApiResponse({
        status: false,
        message: "Error saving : Invalid inputs",
      });
    }

    fetchPlanTableData();
  };

  const handleDeleteButton = async (e) => {
    e.preventDefault();
    let res = null;
    if (planFormData.name !== "" || planFormData.name.length !== 0) {
      res = await deletePlan(loggedUser.token, planFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      if (res.status) {
        clearFormInputs();
      } else {
        setApiResponse({ status: false, message: "Error deleting..." });
      }
    }

    fetchPlanTableData();
  };

  const handleActiveButton = async (e) => {
    e.preventDefault();
    let res = null;

    if (planStatus === "Active") {
      res = await deActivatePlan(loggedUser.token, planFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setPlanStatus("Inactive");
    }

    if (planStatus === "Inactive") {
      res = await activatePlan(loggedUser.token, planFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setPlanStatus("Active");
    }

    fetchPlanTableData();
  };

  //++++ Plan info Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++ Plan Value DataTable  ++++++++++++++++++++++++++++++++
  const planValueTableRef = useRef();
  const [planValueTableRowData, setPlanValueTableRowData] = useState([]);

  const [planValueTableColumnDefs, setPlanValueTableColumnDefs] = useState([
    { colId: "id", valueGetter: (r) => r.data.id, flex: 1, hide: true },
    {
      colId: "index",
      headerName: "#",
      valueGetter: (r) => r.data.index,
      flex: 0.3,
    },
    {
      colId: "insValue",
      headerName: "Inst. Value (Rs)",
      valueGetter: (r) => r.data.valueOfInstallment,
      flex: 1,     
    },
    {
      colId: "total",
      headerName: "Total Value (Rs)",
      valueGetter: (r) => r.data.valueOfInstallment * r.data.noOfInstallment,
      flex: 1,
    },
    {
      colId: "startDate",
      headerName: "Start Date",
      valueGetter: (r) => r.data.startDate,
      flex: 1,
    },
    {
      colId: "endtDate",
      headerName: "End Date",
      valueGetter: (r) => r.data.endDate,
      flex: 1,
    },
  ]);

  const fetchPlanValueTableData = async (planId) => {
    try {
      const res = await getPlanValueListByPlanId(loggedUser.token,planId);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
          if (res.data[i].endDate === null) {
            res.data[i].endDate = "Present";
          }
        }

        setPlanValueTableRowData(res.data);

        // set recent plan values to inputs
        setPlanValueFormData((prevState) => ({
          ...prevState,
          valueOfInstallment: res.data[0].valueOfInstallment,
          noOfInstallment: 12,
          startDate: res.data[0].startDate, //new Date().getFullYear() +'-'+(new Date().getMonth() + 1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
          endDate: "",
          planId: planId,
          errors: {},
        }));
      } else {
        setPlanValueTableRowData([]);
      }
    } catch (error) {
      console.error("Plan -> fetchPlanValueTableData: ", error);
    }
  };

  const handlePlanValueChangeButton = () => {
    setIsPlanValueInputDisabled(!isPlanValueInputDisabled);
    setPlanValueFormData((prevState) => ({
      ...prevState,
      valueOfInstallment: '',
      noOfInstallment: 12,
      startDate: '', //new Date().getFullYear() +'-'+(new Date().getMonth() + 1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      endDate: "",     
    }));
  };

  // basic input
  const [planFormData, setPlanFormData] = useState({
    id: "",
    code: "",
    active: "Active",
    errors: {},
  });

  // plan value input
  const [planValueFormData, setPlanValueFormData] = useState({
    valueOfInstallment: "",
    noOfInstallment: 12,
    startDate: "", //new Date().getFullYear() +'-'+(new Date().getMonth() + 1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
    endDate: "",
    planId:"",
    errors: {},
  });

  const [isPlanValueInputDisabled, setIsPlanValueInputDisabled] =
    useState(false);

  const handleBasicInputChange = (e) => {
    const { name, value } = e.target;
    setPlanFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePlanValueInputChange = (e) => {
    const { name, value } = e.target;
    setPlanValueFormData((pre) => ({ ...pre, [name]: value }));
  };

  // validation
  const [isValidName, setIsValidName] = useState(true);
  const [isValidInstValue, setIsValidInstValue] = useState(true);
  const [isValidStartDate, setIsValidStartDate] = useState(true);

  const isValidInputs = () => {
    const errors = {};

    if (planFormData.code === "" || planFormData.code.length === 0) {
      setIsValidName(false);
      errors.code = "Code is Required";
    }

    if (
      planValueFormData.valueOfInstallment === "" ||
      parseFloat(planValueFormData.valueOfInstallment) <= 0
    ) {
      setIsValidInstValue(false);
      errors.code = "Inst Value is Required";
    }

    if (!planValueFormData.startDate) {
      setIsValidStartDate(false);
      errors.code = "Start Date is Required";
    }

    setPlanFormData((prevState) => ({ ...prevState, errors }));
    // return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // change input validation state
  const setIsValidInputs = (isValid) => {
    setIsValidName(isValid);
    setIsValidInstValue(isValid);
    setIsValidStartDate(isValid);
  };

  // clear inputs
  const clearFormInputs = () => {
    setPlanFormData({
      id: "",
      code: "",
      active: "Active",
      errors: {},
    });

    setPlanValueFormData({
      valueOfInstallment: "",
      noOfInstallment: 12,
      startDate: "", //new Date().getFullYear() +'-'+(new Date().getMonth() + 1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      endDate: "",
      planId: "",
      errors: {},
    });
  };

  // initialize inputs and state
  const initialize = () => {
    //basic
    clearFormInputs();
    setOperationMode("new");
    setIsValidInputs(true);
    setPlanStatus("Active");

    // plan value
    setPlanValueTableRowData([]);
    setIsPlanValueInputDisabled(false);
  };

  //++++ Item Info Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++ Begin Cluster List Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++ Cluster List DataTable  ++++++++++++++++++++++++++++++++
    const clusterListTableRef = useRef();
    const [clusterListTableRowData, setClusterListTableRowData] = useState([]);
  
    const [clusterListTableColumnDefs, setClusterListTableColumnDefs] = useState([
      { colId: "id", valueGetter: (r) => r.data.id, flex: 1, hide: true },
      {
        colId: "index",
        headerName: "#",
        //valueGetter: (r) => r.data.index,
        flex: 0.12,
      },
      {
        colId: "code",
        headerName: "Cluster Code",
        valueGetter: (r) => r.data.code,
        filter: true,
        flex: 1,
      },
      {
        colId: "createdAt",
        headerName: "Created Date",
        valueGetter: (r) => r.data.createdAt,
        filter: true,
        flex: 1,
      },
      {
        colId: "status",
        headerName: "Status",
        valueGetter: (r) => r.data.active,
        filter: true,
        flex: 1,
      },
    ]);
  
    
    const fetchClusterListTableData = async (planId) => {
      try {
        const res = await getClusterListByPlanId(loggedUser.token,planId);
        console.log(res)
        if (res !== undefined) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].index = i + 1;
          }
  
          setClusterListTableRowData(res.data);
        } else {
          setClusterListTableRowData([]);
        }
      } catch (error) {
        console.error("Plan -> fetchClusterListTableData: ", error);
      }
    };
  //++++ End Cluster List Tab Pane  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++ Tab Layout  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [tab_1_Hide, setTab_1_Hide] = useState("");
  const [tab_2_Hide, setTab_2_Hide] = useState("hidden");

  const TAB_ACTIVE_CSS = "text-master_color-400 border-master_color-400";
  const [tab_1_active_css, setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
  const [tab_2_active_css, setTab_2_active_css] = useState("");

  const toggleTab = (id) => {
    if (id === 1) {
      setTab_1_Hide("");
      setTab_2_Hide("hidden");

      setTab_1_active_css(TAB_ACTIVE_CSS);
      setTab_2_active_css("");
    }

    if (id === 2) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("");

      setTab_1_active_css("");
      setTab_2_active_css(TAB_ACTIVE_CSS);
    }
  };
  /****** Right Pane - end *****************************************************************/

  return (
    <div className="flex h-full">
      <div id="left-pane" className="w-1/5 z-10">
        <div
          className={`bg-white absolute top-0 bottom-0 ${leftPaneStyle} border-solid border-r border-slate-300`}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 mt-1.5 ml-2.5">
              <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow mb-1.5">
                Plan List
              </p>
            </div>
            <div className="col-start-2 flex justify-end">
              <button
                onClick={handleExpandLeftPane}
                className="text-master_color-400 font-bold rounded mt-1 mr-1"
              >
                {leftPaneExpandedButtonIcon}
              </button>
            </div>
            <div className="col-span-2 p-0"></div>
          </div>
          <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

          <div className={`h-[calc(100%_-_2.5rem)] p-2`}>
            <div className="ag-theme-quartz h-full">
              <AgGridReact
                ref={planTableRef}
                columnDefs={planTableColumnDefs}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowData={planTableRowData}
                rowSelection={"single"}
                onSelectionChanged={handledOnRowClick}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="right-pane" className="w-4/5 z-0">
        <div id="button-container" className="flex gap-1.5 pl-2">
          <div className="mt-1">
            <button
              type="button"
              onClick={handleNewButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaPlus size={16} />
              &nbsp;New
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={planStatus === "Active" ? false : true}
              onClick={handleSaveButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaSave size={16} />
              &nbsp;Save
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={true}//operationMode === "new" ? true : false}
              onClick={handleDeleteButton}
              className="px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaCircleXmark size={16} />
              &nbsp;Delete
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleActiveButton}
              className="w-28 px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              {planStatus === "Active" ? (
                <FaSquare size={12} color="red" />
              ) : (
                <FaCircle size={12} color="green" />
              )}
              &nbsp;
              {planStatus === "Active" ? "Deactivate" : "Reactivate"}
            </button>
          </div>
          <div className="mt-2.5 ml-6 flex w-auto">
            <Notification response={apiResponse} />
          </div>
        </div>

        <hr className="border-slate-300 border-0.5 mt-1"></hr>

        <div id="tab-container" className="h-[calc(100%_-_6.5rem)]">
          <div
            id="plan-info-tab-pane"
            className={`p-6 pt-1 ${tab_1_Hide}`}
            role="tabpanel"
          >
            <div className="grid grid-cols-3 gap-12">
              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Basic
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Plan Code</div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="code"
                      value={planFormData.code}
                      onChange={handleBasicInputChange}
                      disabled={planStatus === "Active" ? false : true}
                      placeholder="Required"
                      className={`w-full text-sm   pl-2 py-1 outline-none border  focus:border-master_color-200 rounded
                            ${
                              isValidName
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              planStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    {/** label */}
                  </div>
                  <div className="col-span-2">{/** input */}</div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    {/** label */}
                  </div>
                  <div className="col-span-2">{/** input */}</div>
                </div>
              </div>
              <div></div>
              <div></div>

              <div>
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Plan Value / Installment
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Inst. Value
                  </div>
                  <div className="col-span-2 flex">
                    <input
                      type="number"
                      name="valueOfInstallment"
                      value={planValueFormData.valueOfInstallment}
                      onChange={handlePlanValueInputChange}
                      disabled={isPlanValueInputDisabled}
                      placeholder="Required"
                      className={`w-full text-sm pl-2 py-1 outline-none border  focus:border-master_color-200 rounded-l
                            ${
                              isValidInstValue
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              !isPlanValueInputDisabled
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                    <button
                      className="px-4 py-1.5 -ml-1 text-sm font-medium rounded-r text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
                      disabled={planStatus === "Active" ? false : true}
                      onClick={handlePlanValueChangeButton}
                    >
                      Change
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Start Date</div>
                  <div className="col-span-2 flex">
                    <input
                      type="date"
                      name="startDate"
                      value={planValueFormData.startDate}
                      onChange={handlePlanValueInputChange}
                      disabled={isPlanValueInputDisabled}
                      className={`w-full text-sm pl-2 py-1 outline-none border  focus:border-master_color-200 rounded
                        ${
                          isValidStartDate
                            ? "border-slate-400"
                            : "border-red-600"
                        }
                        ${
                          !isPlanValueInputDisabled ? "bg-white" : "bg-gray-200"
                        } 
                        `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Length</div>
                  <div className="col-span-2 pl-1 py-1">
                    {planValueFormData.noOfInstallment} Months
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Total Value
                  </div>
                  <div className="col-span-2 pl-1 py-1">
                    <span className="text-master_color-300 drop-shadow">
                      Rs{" "}
                      {planValueFormData.noOfInstallment *
                        planValueFormData.valueOfInstallment}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-2 row-span-3">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Plan Value History
                </div>
                <div className="ag-theme-quartz h-full pt-1 w-5/6">
                  <AgGridReact
                    ref={planValueTableRef}
                    columnDefs={planValueTableColumnDefs}
                    defaultColDef={useMemo(
                      () => ({ suppressMovable: true }),
                      []
                    )}
                    rowData={planValueTableRowData}
                    rowSelection={"single"}
                    rowHeight={30}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={`p-4 pt-1 ${tab_2_Hide} h-full`}
            id="styled-dashboard"
            role="tabpanel"
          >
            <div className="ag-theme-quartz h-full pt-1">
              <AgGridReact
                ref={clusterListTableRef}
                columnDefs={clusterListTableColumnDefs}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowData={clusterListTableRowData}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[5, 10, 15]}
              />
            </div>
          </div>
        </div>

        <div
          id="tab-button-container"
          className="mb-4 border-b border-gray-200"
        >
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-styled-tab"
            data-tabs-toggle="#default-styled-tab-content"
            data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 "
            data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`}
                id="profile-styled-tab"
                data-tabs-target="#styled-profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => toggleTab(1)}
              >
                Plan Info
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(2)}
              >
                Cluster List
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
