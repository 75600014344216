import { useRef, useState, useEffect, useCallback, useMemo } from "react";

import {
  FaCaretLeft,
  FaCaretRight,
  FaPlus,
  FaCircleXmark,
  FaSquare,
  FaCircle,
} from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import Notification from "../component/utility/Notification";

import { activateCluster, deActivateCluster, deleteCluster, getClusterList, saveCluster, updateCluster } from "../service/ClusterService";
import { getAllActivePlanList } from "../service/PlanService";

export default function Cluster() {
  const loggedUser = JSON.parse(
    atob(sessionStorage.getItem("x$sk72hyQwersgyhk"))
  );

  /****** Left Pane - start *****************************************************************************************************************/
  const [leftPaneStyle, setLeftPaneStyle] = useState("w-1/5");
  const [isLeftPaneExpanded, setIsLeftPaneExpanded] = useState(false);
  const [leftPaneExpandedButtonIcon, setLeftPaneExpandedButtonIcon] = useState(
    <FaCaretRight size={36} />
  );

  //++++ Cluster List DataTable  ++++++++++++++++++++++++++++++++
  const clusterTableRef = useRef();
  const [paginationlHide, setPaginationlHide] = useState(true);
  const [clusterTableRowData, setClusterTableRowData] = useState([]);

  const [clusterTableColumnDefs, setClusterTableColumnDefs] = useState([
    { 
      colId: "id", 
      valueGetter: (r) => r.data.id, 
      flex: 1, 
      hide: true 
    },   
    {
      colId: "code",
      headerName: "Cluster Code",
      valueGetter: (r) => r.data.code,
      filter: true,
      flex: 1,
      hide: false,
    },
    
  ]);

  // functions
  const handleExpandLeftPane = () => {
    if (!isLeftPaneExpanded) setIsLeftPaneExpanded(true);
    else setIsLeftPaneExpanded(false);

    if (isLeftPaneExpanded) {
      setLeftPaneStyle("w-1/5");
      setLeftPaneExpandedButtonIcon(<FaCaretRight size={36} />);

      clusterTableRef.current.api.setColumnsVisible(
        ["code", "createdAt", "active"],
        false
      );
      setPaginationlHide(true);
    } else {
      setLeftPaneStyle("w-screen");
      setLeftPaneExpandedButtonIcon(<FaCaretLeft size={36} />);

      clusterTableRef.current.api.setColumnsVisible(
        ["code", "createdAt", "active"],
        true
      );
      setPaginationlHide(false);
    }
  };

  const fetchClusterTableData = async () => {
    try {
      const res = await getClusterList(loggedUser.token);

      if (res !== undefined) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].index = i + 1;
        }
        setClusterTableRowData(res.data);
      } else {
        setClusterTableRowData([]);
      }
    } catch (error) {
      console.error("Cluster -> fetchClusterTableData: ", error);
    }
  };

  useEffect(() => {
    fetchClusterTableData();
  }, []);

  const handledOnRowClick = useCallback(() => {
    const selectedRows = clusterTableRef.current.api.getSelectedRows();
    
    if (selectedRows.length === 1) {
      setClusterFormData((prevState) => ({
        ...prevState,
        id: selectedRows[0].id,       
        code: selectedRows[0].code,
        active: selectedRows[0].active,         
        planId: selectedRows[0].plan.id
      }));

      setOperationMode("update");
      setApiResponse({
        status: "",
        message: "Finished loading : " + selectedRows[0].code,
      });
      setClusterStatus(selectedRows[0].active);
      setIsValidInputs(true);
    }
  }, []);

  /****** Left Pane - end *******************************************************************************************************************/

  /****** Right Pane - start ****************************************************************************************************************/

  //++++ Button Container  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [operationMode, setOperationMode] = useState("new"); // "new", "save", or "update"
  const [apiResponse, setApiResponse] = useState({
    //status: true, data: null, message: 'Already added : Fabric'
    status: "",
    message: "",
  });

  const [clusterStatus, setClusterStatus] = useState("Active");

  const handleNewButton = () => {
    initialize();
    setApiResponse({ status: "", message: "Add new cluster" });
  };

  const handleSaveButton = async (e) => {
    e.preventDefault();
    setIsValidInputs(true);

    let cluster = {
      id: clusterFormData.id,      
      code: clusterFormData.code.toUpperCase(),
      active: clusterFormData.active,
      planId: clusterFormData.planId
    };

    let resCluster = null;

    if (isValidInputs()) {
      
      if (operationMode === "new") {
        
        resCluster = await saveCluster(loggedUser.token, cluster);
        setApiResponse({ status: resCluster.status, message: resCluster.message });

        if (resCluster != null) {
          if (resCluster.status) {
            initialize();
          }
        }
          
      } else if (operationMode === "update") {
        resCluster = await updateCluster(loggedUser.token, cluster);

        setApiResponse({ status: resCluster.status, message: resCluster.message });

        if (resCluster != null) {
          if (resCluster.status) {
            initialize();
          }
        }
      } else {
        setApiResponse({ status: false, message: "Error" });
      }
      
    } else {
      setApiResponse({
        status: false,
        message: "Error saving : Invalid inputs",
      });
    }

    fetchClusterTableData();
  };

  const handleDeleteButton = async (e) => {
    e.preventDefault();
    let res = null;
    if (clusterFormData.name !== "" || clusterFormData.name.length !== 0) {
      res = await deleteCluster(loggedUser.token, clusterFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      if (res.status) {
        initialize();
      } else {
        setApiResponse({ status: false, message: "Error deleting..." });
      }
    }

    fetchClusterTableData();
  };

  const handleActiveButton = async (e) => {
    e.preventDefault();
    let res = null;

    if (clusterStatus === "Active") {
      res = await deActivateCluster(loggedUser.token, clusterFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setClusterStatus("Inactive");
    }

    if (clusterStatus === "Inactive") {
      res = await activateCluster(loggedUser.token, clusterFormData.id);
      setApiResponse({ status: res.status, message: res.message });
      setClusterStatus("Active");
    }

    fetchClusterTableData();
  };

  //++++ Cluster info Tab Pane  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Basic Inputs
  const [clusterFormData, setClusterFormData] = useState({
    id: "",
    code: "",    
    active: "Active",
    planId: 0,
    errors: {},
  });

  const handleBasicInputChange = (e) => {
    const { name, value } = e.target;
    setClusterFormData((prevState) => ({ ...prevState, [name]: value }));

    if(name == 'planId'){
      setClusterFormData((prevState) =>({...prevState,
        code: e.target.options[e.target.selectedIndex].text+"-"
      }))        
    }
  };

  // plan drop down box
  const[activePlanList, setActivePlanList] = useState([]);

  const fetchActivePlanList = async () => {
    try {
      const req = await getAllActivePlanList(loggedUser.token);
      setActivePlanList(req.data);
    } catch (error) {
      console.log(error);
    }
  }

  // fetch plan list to dropdown
  useEffect(() => {
    fetchActivePlanList();
  }, []);


  // Validation
  const [isValidPlan, setIsValidPlan] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);

  const isValidInputs = () => {
    const errors = {};

    if(clusterFormData.planId == 0){
      setIsValidPlan(false)
      errors.plan = "Plan is Required";
    }
   
    if (!clusterFormData.code) {
      setIsValidCode(false);
      errors.code = "Code is Required";
    }

    setClusterFormData((prevState) => ({ ...prevState, errors }));
    // return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // change input validation state
  const setIsValidInputs = (isValid) => {
    setIsValidCode(isValid);
    setIsValidPlan(isValid)
  };

  // clear inputs
  const clearFormInputs = () => {
    setClusterFormData({
      id: "",      
      code: "",
      active: "Active",
      planId: 0,
      errors: {},
    });
  };

  // initialize inputs and state
  const initialize = () => {
    //basic
    clearFormInputs();
    setOperationMode("new");
    setIsValidInputs(true);
    setClusterStatus("Active");
  };

  //++++ Customer List Tab Pane  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++ Tab Layout  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const [tab_1_Hide, setTab_1_Hide] = useState("");
  const [tab_2_Hide, setTab_2_Hide] = useState("hidden");
  const [tab_3_Hide, setTab_3_Hide] = useState("hidden");

  const TAB_ACTIVE_CSS = "text-master_color-400 border-master_color-400";
  const [tab_1_active_css, setTab_1_active_css] = useState(TAB_ACTIVE_CSS);
  const [tab_2_active_css, setTab_2_active_css] = useState("");
  const [tab_3_active_css, setTab_3_active_css] = useState("");

  const toggleTab = (id) => {
    if (id === 1) {
      setTab_1_Hide("");
      setTab_2_Hide("hidden");
      setTab_3_Hide("hidden");

      setTab_1_active_css(TAB_ACTIVE_CSS);
      setTab_2_active_css("");
      setTab_3_active_css("");
    }

    if (id === 2) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("");
      setTab_3_Hide("hidden");

      setTab_1_active_css("");
      setTab_2_active_css(TAB_ACTIVE_CSS);
      setTab_3_active_css("");
    }

    if (id === 3) {
      setTab_1_Hide("hidden");
      setTab_2_Hide("hidden");
      setTab_3_Hide("");

      setTab_1_active_css("");
      setTab_2_active_css("");
      setTab_3_active_css(TAB_ACTIVE_CSS);
    }
  };

  /****** Right Pane - end ******************************************************************************************************************/

  return (
    <div className="flex h-full">
      <div id="left-pane" className="w-1/5 z-10">
        <div
          className={`bg-white absolute top-0 bottom-0 ${leftPaneStyle} transition-all duration-150 ease-in-out border-solid border-r border-slate-300`}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 mt-1.5 ml-2.5">
              <p className="text-master_color-400 font-semibold text-lg font-sans drop-shadow mb-1.5">
                Cluster List
              </p>
            </div>
            <div className="col-start-2 flex justify-end">
              <button
                onClick={handleExpandLeftPane}
                className="text-master_color-400 font-bold rounded mt-1 mr-1 hidden"
              >
                {leftPaneExpandedButtonIcon}
              </button>
            </div>
            <div className="col-span-2 p-0"></div>
          </div>
          <hr className="border-slate-300 border-0.5 ml-0.5 mr-0.5"></hr>

          <div className={`h-[calc(100%_-_2.5rem)] p-2`}>
            <div className="ag-theme-quartz h-full">
              <AgGridReact
                ref={clusterTableRef}
                columnDefs={clusterTableColumnDefs}
                rowData={clusterTableRowData}
                pagination={!paginationlHide}
                paginationPageSize={10}
                paginationPageSizeSelector={[5, 10, 15]}
                defaultColDef={useMemo(() => ({ suppressMovable: true }), [])}
                rowSelection={"single"}
                onSelectionChanged={handledOnRowClick}
                //onRowDoubleClicked={handledOnRowDoubleClick}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="right-pane" className="w-4/5 z-0">
        <div id="button-container" className="flex gap-1.5 pl-2">
          <div className="mt-1">
            <button
              type="button"
              onClick={handleNewButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaPlus size={16} />
              &nbsp;New
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={clusterStatus === "Active" ? false : true}
              onClick={handleSaveButton}
              className="px-4 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaSave size={16} />
              &nbsp;Save
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleDeleteButton}
              className="px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              <FaCircleXmark size={16} />
              &nbsp;Delete
            </button>
          </div>
          <div className="mt-1">
            <button
              type="button"
              disabled={operationMode === "new" ? true : false}
              onClick={handleActiveButton}
              className="w-28 px-3 py-1.5 text-sm font-medium rounded text-white inline-flex items-center bg-master_color-400 hover:bg-master_color-200"
            >
              {clusterStatus === "Active" ? (
                <FaSquare size={12} color="red" />
              ) : (
                <FaCircle size={12} color="green" />
              )}
              &nbsp;
              {clusterStatus === "Active" ? "Deactivate" : "Reactivate"}
            </button>
          </div>
          <div className="mt-2.5 ml-6 flex w-auto">
            <Notification response={apiResponse} />
          </div>
        </div>

        <hr className="border-slate-300 border-0.5 mt-1"></hr>

        <div id="tab-container" className="h-[calc(100%_-_6.5rem)]">
          <div
            id="cluster-info-tab-pane"
            className={`p-6 pt-1 ${tab_1_Hide}`}
            role="tabpanel"
          >
            <div className="grid grid-cols-3 gap-12">
              <div id="row-1-col-1">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Basic
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">Plan</div>
                  <div className="col-span-2">
                  <select
                    name="planId"
                    value={clusterFormData.planId}
                    onChange={handleBasicInputChange}
                    disabled={clusterStatus === "Active" ? false : true}
                    className={`w-full pl-1 py-1 text-sm outline-none border focus:border-master_color-200 rounded 
                            ${
                              isValidPlan
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              clusterStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            }
                            `}
                  >
                    <option value="0" disabled>
                      -- select plan --
                    </option>
                    {activePlanList.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.code}
                      </option>
                    ))}
                  </select>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">Code</div>
                  <div className="col-span-2">
                    <input
                      type="text"
                      name="code"
                      value={clusterFormData.code}
                      onChange={handleBasicInputChange}
                      disabled={clusterStatus === "Active" ? false : true}
                      className={`w-full text-sm   pl-2 py-1 outline-none border focus:border-master_color-200 rounded                            
                            ${
                              isValidCode
                                ? "border-slate-400"
                                : "border-red-600"
                            }
                            ${
                              clusterStatus === "Active"
                                ? "bg-white"
                                : "bg-gray-200"
                            } 
                            `}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    {/** label */}
                  </div>
                  <div className="col-span-2">{/** input */}</div>
                </div>
              </div>
              <div id="row-1-col-2"></div>
              <div id="row-1-col-3"></div>

              <div id="row-2-col-1">
                <div className="text-master_color-300 text-lg font-semibold drop-shadow-sm">
                  Overview
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Cluster Status
                  </div>
                  <div className="flex pt-0.5"></div>
                </div>
                <div className="grid grid-cols-2 mt-2">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Total Assortment
                  </div>
                  <div className="flex"></div>
                </div>
                <div className="grid grid-cols-2 mt-1">
                  <div className="pt-0.5 font-sans drop-shadow">
                    Created Date
                  </div>
                  <div className="flex"></div>
                </div>                
              </div>

              <div id="row-2-col-2"></div>
              <div id="row-2-col-3"></div>
              <div id="row-3-col-1"></div>
              <div id="row-3-col-2"></div>
              <div id="row-3-col-3"></div>
            </div>
          </div>

          <div
            id="customer-list-tab-pane"
            className={`p-4 pt-1 ${tab_2_Hide} h-full`}
            role="tabpanel"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15831.162623204676!2d80.59938464999999!3d7.2646493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1731697563874!5m2!1sen!2slk"
              width="800"
              height="400"
              title="hi"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div
            id="customer-list-tab-pane"
            className={`p-4 pt-1 ${tab_3_Hide} h-full`}
            role="tabpanel"
          >
            Customer List
          </div>
        </div>

        <div
          id="tab-button-container"
          className="mb-4 border-b border-gray-200"
        >
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-styled-tab"
            data-tabs-toggle="#default-styled-tab-content"
            data-tabs-active-classes="text-master_color-400 hover:text-master_color-400 border-master_color-400 "
            data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 drop-shadow-sm ${tab_1_active_css}`}
                id="profile-styled-tab"
                data-tabs-target="#styled-profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => toggleTab(1)}
              >
                Cluster Info
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4  border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_2_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(0)}
              >
                Assortment List
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-master_color-400 hover:border-master_color-400 ${tab_3_active_css}`}
                id="dashboard-styled-tab"
                data-tabs-target="#styled-dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => toggleTab(0)}
              >
                
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
